<template>
  <div class="test">
    <el-dialog v-model="dialogVisible"
               width="1200px">
      <img w-full
           style="width:100%"
           :src="bigUrl.url"
           alt="Preview Image" />
    </el-dialog>
    <div class="top_button">
      <el-button type="primary"
                 @click="confirmRK">确认入库</el-button>
    </div>
    <div class="top_info">
      <!-- 入库信息 -->
      <div class="storage_info">
        <el-form ref="formRef"
                 label-width="100px"
                 class="demo-ruleForm">
          <el-form-item label="快递单号：">
            <el-input v-model="kdnum"
                      size="large"
                      id="kdnum"
                      @input="kdnumInput"
                      placeholder="请输入/扫描快递单号" />

          </el-form-item>
          <el-form-item label="用户ID："
                        v-if="rkType==2||storageInfo.uid==0">
            <el-input v-model="claimUid"
                      size="large"
                      placeholder="请输入订单所属的UID" />

          </el-form-item>

          <transition name="el-fade-in-linear">
            <el-form-item label="用户名："
                          v-if="storageInfo.username">
              <span>{{storageInfo.username}}</span>
            </el-form-item>
          </transition>

          <transition name="el-fade-in-linear">
            <el-form-item label="用户UID："
                          v-if="storageInfo.uid">
              <span>{{storageInfo.uid}}</span>
            </el-form-item>
          </transition>

          <transition name="el-fade-in-linear">
            <el-form-item label="订单ID："
                          v-if="storageInfo.uid">
              <span>{{storageInfo.id}}</span>
            </el-form-item>
          </transition>

          <transition name="el-fade-in-linear">
            <el-form-item label="上次货架："
                          v-if="uidhjcode">
              <span>{{uidhjcode}}</span>
            </el-form-item>
          </transition>

          <transition name="el-fade-in-linear">
            <el-form-item label="货架号："
                          v-if="selectHjcode">
              <span>{{selectHjcode}}</span>
            </el-form-item>
          </transition>

          <transition name="el-fade-in-linear">
            <el-form-item label="货物类型："
                          v-show="rkType">

              <el-radio-group v-model="goodsType">
                <el-radio label="1"
                          size="large">普货</el-radio>
                <el-radio label="2"
                          size="large">特货</el-radio>
              </el-radio-group>
            </el-form-item>
          </transition>

          <transition name="el-fade-in-linear">
            <el-form-item label="货物重量：">
              <el-input v-model="weight"
                        size="large"
                        id="weight"
                        placeholder="请输入/扫描货物重量">
                <template #suffix>
                  KG
                </template>
              </el-input>
            </el-form-item>
          </transition>

          <transition name="el-fade-in-linear">
            <el-form-item label="备注信息："
                          v-if="storageInfo.info">
              <span v-html="storageInfo.info"
                    style='color:#eb3941;font-weight:700'></span>
            </el-form-item>
          </transition>

          <el-collapse v-model='activerBox'>

            <el-collapse-item v-for="(item,index) in zxdInfo"
                              :title="`第${index+1}箱`"
                              :name="index">
              <div class="addGoods">
                <el-button type="success"
                           :icon="Check"
                           circle
                           @click="addGoods(index)">+</el-button>
              </div>
              <div class="goodsName"
                   v-for="(item_g,index) in item.wpinfo">

                <el-form-item label="物品名称：">
                  <el-input v-model="item_g.name"
                            size="large"
                            placeholder="请输入物品名称" />
                </el-form-item>
              </div>
              <!-- <el-form-item label="货物重量：">
                <el-input v-model="zxdInfo[index].weight"
                          size="mini"
                          id="weight"
                          placeholder="请输入/扫描货物重量">
                  <template #suffix>
                    KG
                  </template>
                </el-input>
              </el-form-item> -->

            </el-collapse-item>
          </el-collapse>

        </el-form>
      </div>
      <!-- 货架选择 -->
      <div class="goods_info">
        <el-tabs type="border-card"
                 v-model="q_activeName"
                 class="demo-tabs">
          <el-tab-pane :label="item.code+'区'"
                       v-for="(item) in goodsCode"
                       :key="item.id"
                       :name='item.code'>
            <div class="codeInfo">
              <div class="code_info_top">
                <el-tag>{{item.info}}</el-tag>
              </div>
              <div class="code_info_view">

                <el-tabs type="border-card"
                         v-model="h_activeName">
                  <el-tab-pane :label="item_hj.code+' 货架'"
                               v-for="(item_hj) in item.children"
                               :key="item_hj.id"
                               :name='item_hj.code'>

                    <div v-for="item_c in item_hj.children"
                         :key="item_c.id">

                      <div v-for="item_g in item_c.children"
                           :key="item_g.id">

                        <div class="selecrt_item"
                             :class="selectHjcode==item_g.hjcode?'activeCode':''"
                             :style="uidhjcode==item_g.hjcode?'border-color:#137333;color:#137333':''"
                             @click="setSelectCode(item_g.hjcode)">
                          <div class="selecrt_item_text">
                            {{item_g.hjcode}}
                          </div>
                          <el-progress :percentage="setItemProgress(item_g.number)"
                                       :show-text='false'
                                       :status="item_g.number<15?'':item_g.number<20?'warning':'exception'" />
                        </div>

                      </div>

                    </div>
                  </el-tab-pane>
                </el-tabs>

              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- 上传图片 -->
    <div class="bottom_info">
      <el-upload v-model:file-list="imageList"
                 :action="upUrl"
                 ref="updataFile"
                 list-type="picture-card"
                 :on-success="handleAvatarSuccess"
                 :on-change='handleAvatarChange'
                 :before-upload="beforeAvatarUpload"
                 :http-request="submitUpload"
                 :on-preview="handlePictureCardPreview"
                 :on-remove="handleRemove"
                 :headers='updataHeader'>
        +
      </el-upload>

    </div>

    <!-- 这里是获取base64的图片 -->
    <el-input v-model="images"
              style="width:100px;margin:0;position:fixed; bottom:0;right:100px"
              size="large"
              @input="getImage"
              id="images"
              type="textarea" />
  </div>
</template>

<script>
import { postWarehouseLocation, posyOutCome } from '../request/api'
import { ElMessage, ElMessageBox } from 'element-plus'
import axios from "axios"

import { debounce } from '../assets/common'
export default {
  data () {
    return {
      //-------货架区域-------
      h_activeName: '',
      //-------选择区域-------
      q_activeName: '',
      //-------用户上次的货架-------
      uidhjcode: '',
      //-------用户认领UID-------
      claimUid: '',
      //-------大图Url-------
      bigUrl: {},
      //-------是否显示大图-------
      dialogVisible: false,
      //-------选择的箱子-------
      activerBox: [],
      //-------上传的图片数组-------
      upImageList: [],

      //-------图片数组-------
      imageList: [],
      //-------上传图片请求头-------
      updataHeader: {
        XTOKIN: ''
      },
      //  -------上传图片的数组-------
      urlImageArr: [],
      // ------入库类型 1.用户下单情况 2.认领件------
      rkType: '',
      // ------选择的货架号------
      selectHjcode: '',
      // ------入库信息------
      storageInfo: {

      },
      // ------快递单号------
      kdnum: '',
      // ------重量------
      weight: '',
      // ------图片------
      images: '',
      // ------仓库ID------
      wid: '',
      // 订单货物类型
      goodsType: '1',
      // 货架号数组
      goodsCode: [],
      // ------装箱单信息------
      zxdInfo: [],

    }
  },
  methods: {
    submitUpload (raw) {
      for (const key in this.upImageList) {
        if (raw.file.name == this.upImageList[key].name) {
          return
        }
      }
      //this.$refs.upload.submit();
      let file = raw.file;//这里获取上传的文件对象
      let formData = new FormData();
      formData.append("file", file);
      axios.post(this.upUrl, formData).then(res => {
        this.upImageList.push(res.data)
      })
    },

    // 移除图片
    handleRemove (e, d) {
      for (const key in this.upImageList) {
        if (this.upImageList[key].name == e.name) {
          return this.upImageList.splice(key, 1)
        }
      }
    },
    // 显示大图
    handlePictureCardPreview (e) {
      this.bigUrl = e
      this.dialogVisible = true
    },
    // 确定入库
    async confirmRK () {
      if ( this.selectHjcode=='') {
        return this.$message.error('未选择货架')
      }

      for (const key in this.zxdInfo) {
        this.zxdInfo[key].no = parseInt(key) + 1
        this.zxdInfo[key].weight = this.weight
        this.zxdInfo[key].hjcode = this.selectHjcode
      }

      
      const res = await posyOutCome({
        op: 'ruku',
        ckid: this.wid,
        pd: 'okruku',
        uid: this.claimUid || '',
        renorder: this.rkType == 2 ? this.claimUid == '' ? 1 : '' : '',
        kdnum: this.kdnum,
        zxdinfo: JSON.stringify(this.zxdInfo),
        usergoodstype: this.goodsType,
        credential: {
          image: this.upImageList
        },
      })

      if (res.errno == 0) {
        this.$message.success(res.message)
        setTimeout(() => {
          location.reload()
        }, 2000);
      }

    },
    // 添加物品
    addGoods (x_index) {
      if (this.zxdInfo[x_index].hasOwnProperty('wpinfo') == false) {
        this.zxdInfo[x_index].wpinfo = []
      }
      this.zxdInfo[x_index].wpinfo.push({})
    },
    // 获取图片
    getImage: debounce(function () {
      if (this.images == '') {
        return
      }
      var d = new Date().valueOf()
      let arr = this.images.split(',');
      let ext = arr[0].match(/:(.*?);/)[1].split('/')[1]
      const image = this.dataURLtoBlob(this.images, d + '.' + ext)
      this.$refs.updataFile.handleStart(image)
      this.$refs.updataFile.submit();

      // this.submitUpload(image)
      this.images = ''
    }),


    // bese4转文件流
    dataURLtoBlob (dataurl, name) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], name, {
        type: mime,
      })
    },

    // bese4转二进制流
    dataURItoBlob (dataURI, type) {
      var binary = atob(dataURI.split(',')[1]);
      var array = [];
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], { type: type });
    },
    // 上传限制
    async beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 或 PNG 格式!');
      }
      if (!isLt2M) {
        const _this = this
        return new Promise(resolve => {
          const reader = new FileReader()
          const image = new Image()
          image.onload = () => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            let rate = image.width / image.height
            let width = ''
            let height = ''
            if (image.width > 1280 || image.height > 1280) {
              width = rate > 1 ? 1280 : 1280 * rate;
              height = rate < 1 ? 1280 : 1280 / rate;
            }
            canvas.width = width;
            canvas.height = height;
            context.clearRect(0, 0, width, height);
            context.drawImage(image, 0, 0, width, height);
            const dataUrl = canvas.toDataURL(file.type);
            const blobData = _this.dataURItoBlob(dataUrl, file.type);
            resolve(blobData)
          }
          reader.onload = (e => { image.src = e.target.result; });
          reader.readAsDataURL(file);
        })
      }
      return true
    },
    // 状态变化回调
    handleAvatarChange (e, d) {
      // this.upImageList = d
    },
    // 上传成功回调
    handleAvatarSuccess (res, d, a) {
      // this.upImageList.push(res)
    },
    // 设置选择的货架值
    setSelectCode (code) {
      this.selectHjcode = code
    },
    // 转换百分比
    setItemProgress (num) {
      if (num > 30) {
        return 100
      } else {
        return (num / 30) * 100
      }
    },
    // 快递单号获取
    kdnumInput: debounce(function () {
      if (this.kdnum == '') {
        return this.$message.error('快递单号不能为空')
      }
      posyOutCome({
        op: 'ruku',
        ckid: this.wid,
        pd: 'kdtooid',
        kdnum: this.kdnum
      }).then(res => {
        if (res.errno == 0) {
          this.rkType = 1
          this.storageInfo = res.order
          this.goodsType = res.order.ext.usergoodstype + '' || '1'
          // this.weight = res.zxdinfo[0].weight || 0
          if (!res.order.ext.credential) {
            res.order.ext.credential = []
            res.order.ext.credential.image = []
          } else if (!res.order.ext.credential.image) {
            res.order.ext.credential.image = []
          }

          this.imageList = [...this.imageList, ...res.order?.ext?.credential?.image];
          this.upImageList = [...this.imageList, ...res.order?.ext?.credential?.image];

          if (res.uidhjcode[0]) {
          this.uidhjcode = res.uidhjcode[0] || ''
            let data = res.uidhjcode[0].split('-')
            this.q_activeName = data[0]
            this.h_activeName = data[1]
          }

          let zxd = res.zxdinfo
          if (!zxd[0]) {
            zxd = [{ wpinfo: [{}] }]
          }
          this.selectHjcode = zxd[0].hjcode
          zxd.forEach((e, i) => {
            this.activerBox.push(i)
          });
          this.zxdInfo = zxd
          
        } else if (res.errno == 1) {
          ElMessageBox.confirm(
            `您输入的快递号【${this.kdnum}】并未在系统中下单,是否需要添加认领件？`,
            '找不到订单！！',
            {
              confirmButtonText: '添加',
              cancelButtonText: '取消',
              type: 'warning',
            }
          )
            .then(() => {
              this.rkType = 2;
              this.storageInfo = { ext: {} };
              this.zxdInfo = [{ wpinfo: [{}] }];
              this.goodsType = '1';
              this.selectHjcode = ''
              this.activerBox = [0]
              this.uidhjcode = ''
              this.q_activeName = ''
              this.h_activeName = ''
            })
            .catch(() => {

            })
        }
      })

    }, 1000)
    ,
    // 获取货架信息
    async getWareHouse () {
      const res = await postWarehouseLocation({
        ckid: this.wid
      })
      if (res.errno == 0) {
        this.goodsCode = res.gsinfo;
      }

    }
  },
  computed: {
    upUrl () {
      if (process.env.NODE_ENV == 'production') {
        return 'https://www.tuanyun99.com/wxoms/app/index.php?c=entry&do=upfile&m=wxl_wms&op=upload&i=2';
      } else if (process.env.NODE_ENV == 'development') {
        return '/index.php?c=entry&do=upfile&m=wxl_wms&op=upload&i=2';
      } else {
        return ''
      }

    },
  },
  async created () {
    let wid = JSON.parse(localStorage.getItem('ty_warehouseData'))
    let cklist = JSON.parse(localStorage.getItem('cklist'))
    const XTOKIN = localStorage.getItem('tuangyin_ck_userinfo')
    this.updataHeader.XTOKIN = JSON.parse(XTOKIN).token
    if (cklist) {
      if (wid) {
        this.wid = wid.id
      } else {
        this.wid = cklist.cklist[0].id
      }
      this.getWareHouse()
    } else {
      await this.$store.dispatch('cklist')
      localStorage.setItem('cklist', this.$store.state.warehouse)
      if (wid) {
        this.wid = wid.id
      } else {
        this.wid = cklist.cklist[0].id
      }
      this.getWareHouse()
    }
  }
}
</script>

<style lang='scss'>
.test {
  padding: 40px;
  box-sizing: border-box;
  .addGoods {
    display: flex;
    margin: 10px 0;
    justify-content: flex-end;
    .el-button {
      width: 25px;
      height: 25px;
    }
  }
  .top_button {
    width: 100%;
    height: 50px;
    display: flex;
    padding: 0 20px;
    box-sizing: border-box;
    justify-content: flex-end;
  }
  .top_info {
    display: flex;
    .storage_info,
    .goods_info {
      flex: 1;
    }
    .el-form-item {
      margin-bottom: 10px;
    }
    .storage_info {
      padding: 20px;
      box-sizing: border-box;
    }
    .goods_info {
      .el-tabs {
        height: 100%;
        overflow: hidden;
        border-radius: 5px;
        .el-tag {
          width: 100%;
          margin-bottom: 10px;
        }
      }
      .el-radio-group {
        width: 100%;
      }
      .activeCode {
        color: #409eff;
        border: 1px solid #409eff !important;
      }
      .selecrt_item {
        width: 100px;
        height: 50px;
        display: flex;
        cursor: pointer;
        font-size: 14px;
        position: relative;
        border-radius: 5px;
        margin-bottom: 10px;
        align-items: center;
        transition: all 0.3s;
        padding: 0 2px 2px 2px;
        flex-direction: column;
        border: 1px solid #eee;
        justify-content: flex-end;

        .selecrt_item_text {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          position: absolute;
          align-items: center;
          justify-content: center;
        }
        .el-progress {
          width: 100%;
        }
      }
    }
  }
  .bottom_info {
    width: 100%;
    height: 200px;
    margin-top: 20px;
  }
}
</style>